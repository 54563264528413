<template>
    <kurcc-data-table-page-template :default-item="defaultItem"
                                    :headers="headers"
                                    actions-suffix="Form"
                                    data-table-title="Form List"
                                    delete-dialog-title="Are you sure you want to delete this form?"
                                    initial-sort-by-column="created_at"
                                    show-update-action
                                    toolbar-button-dialog-title="New Form"
                                    toolbar-button-text="New Form">
    </kurcc-data-table-page-template>
</template>

<script>
export default {
    name: "KurccFormsPage",
    components: {
        KurccDataTablePageTemplate: () => import('@/modules/app/templates/KurccDataTablePageTemplate')
    },
    data() {
        return {
            headers: [
                {
                    text: 'Title',
                    align: 'start',
                    value: 'title',
                    sortable: false
                },
                {
                    text: 'Description',
                    value: 'description',
                    sortable: false
                },
                {text: this.$vuetify.lang.t('$vuetify.general.status'), value: 'enabled'},
                {text: 'Is Draft', value: 'is_draft_datatable_display_ready', sortable: false},
                {
                    text: 'Created at',
                    value: 'created_at'
                },
                {text: this.$vuetify.lang.t('$vuetify.general.actions'), value: 'actions', sortable: false},
            ],
            defaultItem: {},
        }
    },
}
</script>
